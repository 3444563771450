<template>
  <div
    class="add-teacher-code-box"
    :style="`background-image: url(${require('@/assets/images/bg.png')})`"
  >
    <img src="@/assets/images/title.png" class="img img1" alt="" />
    <img src="@/assets/images/desc.png" class="img img2" alt="" />
    <img src="@/assets/images/icon_1.png" class="icon icon1" alt="" />
    <img src="@/assets/images/icon_2.png" class="icon icon2" alt="" />
    <img src="@/assets/images/icon_3.png" class="icon icon3" alt="" />
    <img src="@/assets/images/xuishi.png" class="xuishi" alt="" />
    <img src="@/assets/images/shouzhi.png" class="shouzhi" alt="" />
    <img v-if="weChatCode" :src="weChatCode" alt="" class="code" />
  </div>
</template>

<script>
import { getWeChatCode } from "@/api";
import flowPage from "../../store/flow-page/flow-page";
export default {
  name: "add-teacher-code",

  data() {
    return {
      weChatCode: "",
    };
  },

  created() {
    this.getWeChatCode();
    document.title = "";
  },

  methods: {
    getWeChatCode() {
      let id = this.$route.query.id;
      getWeChatCode({ flowId: id }).then((res) => {
        this.weChatCode = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-teacher-code-box {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: #c8e2b8;
  .xuishi {
    height: 8.6vw;
    position: absolute;
    bottom: 8.6vw;
    left: 50%;
    transform: translateX(-50%);
  }
  .icon1 {
    top: 117vw;
    left: 15.7vw;
  }
  .icon2 {
    top: 117vw;
    right: 14.25vw;
  }
  .icon3 {
    top: 134vw;
    left: 33.5vw;
  }
  .icon {
    position: absolute;
    width: 28.7vw;
  }
  .img {
    display: block;
    margin: auto;
  }

  .img2 {
    height: 6.7vw;
    margin-top: 5.7vw;
  }

  .img1 {
    height: 6.7vw;
    padding-top: 22vw;
  }

  .code {
    position: absolute;
    height: 56vw;
    width: 56vw;
    border: 1px solid #ccc;
    left: 50%;
    transform: translateX(-50%);
    top: 53vw;
  }

  .shouzhi {
    position: absolute;
    height: 13vw;
    width: 13vw;
    top: 40vw;
    left: 50%;
    margin-left: -6.5vw;
    animation: 0.5s linear 0s infinite alternate addTeacherCode;
  }
}
@keyframes addTeacherCode {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}
</style>